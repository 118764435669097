import { Formik, Form, FormikProps } from 'formik';
import { getProfileFormik, IProfileValues } from './profileFormik';
import {
    FormikPhoneField,
    FormikTextField,
    FormikSexField,
    FormikDateField,
    FormikFileField,
} from '../../../components/FormikFields';
import React from 'react';
import { Button, Divider, styled } from '@mui/material';
import { Text } from '../../../components/Text';
import { User, Profile, FileInfo } from '../../../dto';
import { getProfileDocumentRequest } from './getProfileDocumentRequest';
import { saveToClient } from '../../../utils/files';
import { ProfileDocumentType } from '../../../types';

type Props = {
    profile?: Profile;
    disabled?: boolean;
    onShowPasswordDialog?: () => void;
    formik: FormikProps<IProfileValues>
    handleSubmit?: () => void
};

const Container = styled('div')({
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
});

export const ProfileForm = ({ profile, disabled, onShowPasswordDialog, formik, handleSubmit }: Props) => {
    const handleDownload = (type: ProfileDocumentType, fileInfo: FileInfo | undefined) => {
        if (!fileInfo) return undefined;

        return () => getProfileDocumentRequest.request(type).then(data => saveToClient(data, fileInfo.name));
    };

    return (
        <Form>
            <Container>
                <Text variant="Title">Основная информация</Text>
                <FormikTextField disabled required formik={formik} name="name" label="Имя" />
                <FormikTextField disabled required formik={formik} name="surname" label="Фамилия" />
                <FormikTextField disabled formik={formik} name="patronymic" label="Отчество" />
                <FormikPhoneField
                    disabled
                    required
                    formik={formik}
                    name="phone"
                    label="Телефон"
                    variant="outlined"
                />
                <FormikTextField
                    disabled
                    required
                    formik={formik}
                    name="email"
                    label="Электронная почта"
                    type="email"
                />

                {!!onShowPasswordDialog && <Button onClick={onShowPasswordDialog}>Изменить пароль</Button>}

                <Text variant="Title">Дополнительные сведения</Text>

                <FormikSexField formik={formik} name="sex" />
                <FormikDateField
                    required
                    formik={formik}
                    name="birthDate"
                    label="Дата рождения"
                    openToYear
                    disabled={disabled}
                />
                <FormikTextField required formik={formik} name="passportSeria" label="Серия паспорта" />
                <FormikTextField
                    required
                    formik={formik}
                    name="passportNumber"
                    label="Номер паспорта"
                    disabled={disabled}
                />
                <FormikTextField
                    required
                    formik={formik}
                    name="issuedBy"
                    label="Кем выдан"
                    disabled={disabled}
                />
                <FormikDateField
                    required
                    formik={formik}
                    name="issuedAt"
                    label="Дата выдачи"
                    disabled={disabled}
                />
                <FormikTextField
                    required
                    formik={formik}
                    name="passportDptCode"
                    label="Код подразделения"
                    disabled={disabled}
                />
                <FormikTextField
                    required
                    formik={formik}
                    name="birthPlace"
                    label="Место рождения (как в паспорте)"
                    disabled={disabled}
                />
                <FormikTextField
                    required
                    formik={formik}
                    name="regAddress"
                    label="Адрес регистрации (как в паспорте)"
                    disabled={disabled}
                />
                <FormikTextField
                    required
                    formik={formik}
                    name="liveAddress"
                    label="Адрес фактического проживания"
                    disabled={disabled}
                />
                <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                <FormikTextField required formik={formik} name="TIN" label="ИНН (номер)" disabled={disabled} />
                <FormikTextField
                    required
                    formik={formik}
                    name="SNILS"
                    label="СНИЛС (номер)"
                    disabled={disabled}
                />

                <FormikTextField
                    required
                    formik={formik}
                    name="bankName"
                    label="Наименование банка"
                    disabled={disabled}
                />
                <FormikTextField required formik={formik} name="BIK" label="БИК банка" disabled={disabled} />
                <FormikTextField
                    required
                    formik={formik}
                    name="correspondentAccount"
                    label="Номер корреспондентского счета (к/с)"
                    disabled={disabled}
                />
                <FormikTextField
                    required
                    formik={formik}
                    name="bankAccount"
                    label="Номер расчетного счета (р/с)"
                    disabled={disabled}
                />
                <FormikFileField
                    required
                    formik={formik}
                    name="bankInfoScan"
                    label="Скан/фото/скриншот банковских реквизитов"
                    onDownload={handleDownload('main', profile?.bankInfoScan)}
                    fileInfo={profile?.bankInfoScan}
                    disabled={disabled}
                />
                <FormikFileField
                    required
                    formik={formik}
                    name="passportMain"
                    label="Скан/фото основного разворота паспорта"
                    onDownload={handleDownload('main', profile?.passportScans.main)}
                    fileInfo={profile?.passportScans.main}
                    disabled={disabled}
                />
                <FormikFileField
                    required
                    formik={formik}
                    name="passportRegistration"
                    label="Скан/фото страницы паспорта с регистрацией"
                    onDownload={handleDownload('registration', profile?.passportScans.registration)}
                    fileInfo={profile?.passportScans.registration}
                    disabled={disabled}
                />
                <FormikFileField
                    required
                    formik={formik}
                    name="passportSelfie"
                    label="Фото селфи с паспортом"
                    onDownload={handleDownload('selfie', profile?.passportScans.selfie)}
                    fileInfo={profile?.passportScans.selfie}
                    disabled={disabled}
                />
                <FormikFileField
                    required
                    formik={formik}
                    name="SNILSScan"
                    label="СНИЛС (фото/скан/скриншот из Госуслуг)"
                    onDownload={handleDownload('snils_scan', profile?.SNILSScan)}
                    fileInfo={profile?.SNILSScan}
                    disabled={disabled}
                />
                {!disabled && handleSubmit && (
                    <Button onClick={handleSubmit} variant="contained" disabled={formik.isSubmitting}>
                        Сохранить
                    </Button>
                )}
            </Container>
        </Form>
    );
};
