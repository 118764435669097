import { FakeRequest } from '../api/FakeRequest';
import { AverageProfitability, serializer } from '../dto';
import { HttpRequest } from '../api/HttpRequest';
import { mockApi } from '../api/constants';

export const averageProfitabilityRequest = !mockApi
    ? new HttpRequest<void, AverageProfitability>({
          method: 'get',
          path: () => `/accounting/average-data/`,
          mapper: data => serializer.deserializeObject(data, AverageProfitability) as AverageProfitability,
      })
    : new FakeRequest<void, AverageProfitability>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                    averageProfitability: '14.134'
                  },
                  AverageProfitability
              ) as AverageProfitability,
      });
