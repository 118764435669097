import React from 'react';
import { authStore } from '../../store/authStore';
import { observer } from 'mobx-react';
import { Layout } from '../../components/Layout';
import { ChangePasswordForm } from '../ProfilePage/ChangePasswordForm';
import { useHistory } from 'react-router';
import { styled } from '@mui/material';
import { Text } from '../../components/Text';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
});

export const ChangePasswordPage = observer(() => {
    const user = authStore.user;
    if (!user) return null;

    const history = useHistory();

    return (
        <Layout>
            <Container>
                <Text variant="TitleBold">Измените пароль для входа</Text>
                <ChangePasswordForm onComplete={() => history.push('/')} />
            </Container>
        </Layout>
    );
});
