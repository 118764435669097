import { FormikContextType } from 'formik';
import { FormHelperText, FormControl } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

type FormikDateFieldProps<TValues extends {}, TFieldName extends keyof TValues & string> = {
    name: TValues[TFieldName] extends Date | null ? TFieldName : never;
    formik: FormikContextType<TValues>;
    openToYear?: boolean;
    label?: string;
    required?: boolean;
    disabled?: boolean;
};

export const FormikDateField = <TValues extends {}, TFieldName extends keyof TValues & string>({
    formik,
    name,
    openToYear,
    label,
    required,
    disabled,
}: FormikDateFieldProps<TValues, TFieldName>) => {
    const value = formik.values[name] as Date | null;
    const error = formik.errors[name]?.toString();
    const touched = formik.touched[name];

    const handleChange = (v: Date | null | undefined) => {
        formik.setFieldValue(name, v || null, true);
        formik.setFieldTouched(name, true);
    };

    return (
        <FormControl variant="outlined">
            <MobileDatePicker<Date>
                label={required ? label + ' *' : label}
                views={['year', 'month', 'day']}
                format="d.M.yyyy"
                openTo={openToYear ? 'year' : undefined}
                localeText={{ toolbarTitle: '', cancelButtonLabel: 'Отмена' }}
                value={value}
                onChange={handleChange}
                disabled={disabled}
            />
            {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};
