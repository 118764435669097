import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
class UserRegistrationData {
    @JsonProperty({ name: 'first_name' })
    name: string = '';

    @JsonProperty({ name: 'last_name' })
    surname: string = '';

    @JsonProperty({ name: 'middle_name' })
    patronymic: string | null | undefined;

    @JsonProperty()
    email: string = '';

    @JsonProperty()
    phone: string = '';
}

@JsonObject()
export class RegistrationData extends UserRegistrationData {
    @JsonProperty({ name: 'smart-token' })
    smartToken: string = ''
}

export const getRegistrationData = (input: RegistrationData): RegistrationData => {

    return Object.assign(new RegistrationData(), input);
};
