import { Layout } from '../../components/Layout';
import { Text } from '../../components/Text';
import { finishRegistrationRequest } from './finishRegistrationRequest';
import { authStore } from '../../store/authStore';
import { useHistory } from 'react-router';
import { ProfileInput, getFileInput } from '../../dto';
import { messageStore } from '../../store/messageStore';
import { IProfileValues, getProfileFormik } from '../common/ProfileForm/profileFormik';
import { ProfileForm } from '../common/ProfileForm';
import { reloadUser } from '../../utils/users';
import { Formik } from 'formik';

export const FinishRegistrationPage = () => {
    const history = useHistory();
    const { user } = authStore;
    if (!user) return null;

    async function submit(values: IProfileValues) {
        const {
            passportMain,
            passportRegistration,
            passportSelfie,
            bankName,
            BIK,
            bankAccount,
            correspondentAccount,
            SNILSScan,
            bankInfoScan,
            issuedAt,
            birthDate,
            ...rest
        } = values;

        const input: ProfileInput = {
            ...rest,
            issuedAt: issuedAt!,
            birthDate: birthDate!,
            passportScans: {
                main: await getFileInput(passportMain!),
                registration: await getFileInput(passportRegistration!),
                selfie: await getFileInput(passportSelfie!),
            },
            bankInfo: {
                bankName,
                BIK,
                account: bankAccount,
                correspondentAccount,
            },
            SNILSScan: await getFileInput(SNILSScan!),
            bankInfoScan: await getFileInput(bankInfoScan!),
        };

        await finishRegistrationRequest
            .request(input)
            .then(() => messageStore.success('Регистрация завершена, дождитесь верификации учетной записи'))
            .then(reloadUser)
            .then(() => history.push('/'));
    }

    return (
        <Layout>
            <Text variant="TitleBold">Завершение регистрации</Text>
            <Formik {...getProfileFormik(user, null, submit)}>
                {formik => <ProfileForm formik={formik} handleSubmit={() => formik.handleSubmit()} />}
            </Formik>
        </Layout>
    );
};
