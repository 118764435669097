import { useRequest } from '../../hooks/useRequest';
import { depositTypesRequest } from '../../store/depositTypesRequest';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { depositCalculationsStore } from '../../store/depositCalculationsStore';
import { Box, Button, CircularProgress, LinearProgress, styled } from '@mui/material';
import { Layout } from '../../components/Layout';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { CreateDepositOptions } from '../common/CreateDepositOptions';
import { Text } from '../../components/Text';
import { createDepositRequest } from './createDepositRequest';
import { getDepositInput } from '../../dto/DepositInputDto';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import { authStore } from '../../store/authStore';
import { UserStatus } from '../../types';
import { CreateDepositAndFinishRegistration } from './CreateDepositPageAndFinishRegistration';
import { ConfirmCreateDepositOptions } from '../common/CreateDepositOptions/ConfirmCreateDepositOptions';
import { averageProfitabilityRequest } from '../../store/averageProfitabilityRequest';

const Root = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const CreateDepositPage = observer(() => {
    const { data: depositTypesRequestData, loading: depositTypesRequestLoading } = useRequest(depositTypesRequest, {});
    const { data: averageProfitabilityRequestData, loading: averageProfitabilityRequestLoading } = useRequest(averageProfitabilityRequest, undefined);
    const { user } = authStore;

    const history = useHistory();

    const loading = depositTypesRequestLoading || averageProfitabilityRequestLoading;

    const [step, setStep] = useState<0 | 1>(0)

    useEffect(() => {
        depositCalculationsStore.init(depositTypesRequestData || [], averageProfitabilityRequestData?.averageProfitability || '');
    }, [depositTypesRequestData, averageProfitabilityRequestData]);

    const onCreate = useCallback(() => {
        createDepositRequest
            .request(
                getDepositInput({
                    depositId: depositCalculationsStore.depositType?.id || '0',
                    duration: depositCalculationsStore.duration || 0,
                    firstPayment: depositCalculationsStore.sum || 0,
                    alias: depositCalculationsStore.alias,
                })
            )
            .then(() => history.push('/'));
    }, [history]);

    const isCreating = createDepositRequest.isLoading;

    if (!user) return <CircularProgress />;

    if (user.status === UserStatus.DRAFT) {
        return (
            <CreateDepositAndFinishRegistration />
        )
    }

    return (
        <Layout title={'Новый продукт'}>
            {(loading || isCreating) && <LinearProgress />}
            {averageProfitabilityRequestData && depositTypesRequestData && (
                <Root>
                    <Breadcrumbs paths={[{ title: 'инвестиции', link: '/' }, { title: 'вклад' }]} />
                    {step === 0 && <>
                        <Text variant="HeaderBold">Открыть новый продукт</Text>
                        <CreateDepositOptions disabled={isCreating} />
                        <Button
                            onClick={() => setStep(1)}
                            disabled={!!depositCalculationsStore.error || isCreating}
                            color="primary"
                            variant="contained"
                        >
                            Создать Инвесткопилку
                        </Button>
                    </>
                    }
                    {step === 1 && <>
                        <Text variant="HeaderBold">Подтвердите выбор</Text>
                        <ConfirmCreateDepositOptions />
                        <Button
                            onClick={() => setStep(0)}
                            variant="contained"
                            sx={{
                                bgcolor: 'background.default',
                                color: 'primary.main',
                                '&:hover': {
                                    bgcolor: 'background.default'
                                }
                            }}
                        >
                            Назад
                        </Button>
                        <Button
                            onClick={onCreate}
                            disabled={!!depositCalculationsStore.error || isCreating}
                            variant="contained"
                        >
                            Подтвердить
                        </Button>
                    </>}
                </Root>
            )}
        </Layout>
    );
});
