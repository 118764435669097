import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { Tokens, User } from '../dto';
import { UserStatus } from '../types';

type StoredTokens = Tokens;

const STORAGE_KEY = 'tokens';

export class AuthStore {
    constructor() {
        makeObservable(this);

        this.loadFromStorage();
    }

    saveToStorage = () => {
        if (this.authToken && this.refreshToken) {
            localStorage.setItem(STORAGE_KEY, JSON.stringify({ access: this.authToken, refresh: this.refreshToken }));
        } else {
            localStorage.removeItem(STORAGE_KEY);
        }
    };

    loadFromStorage() {
        try {
            const str = localStorage.getItem(STORAGE_KEY);
            if (!str) return null;
            const tokens = JSON.parse(str) as StoredTokens;
            if (tokens) this.setAuthToken(tokens);
        } catch (_) {}
    }

    @observable
    private _user: User | null = null;

    @computed
    get user() {
        return toJS(this._user);
    }

    @observable
    authToken: string | null = null;

    @observable
    refreshToken: string | null = null;

    @action
    setAuthToken = (tokens: Tokens) => {
        this.authToken = tokens.access;
        this.refreshToken = tokens.refresh;
        this.saveToStorage();
    };

    @action
    logout = () => {
        this.authToken = null;
        this.refreshToken = null;
        this._user = null;
        this.saveToStorage();
    };

    @action
    setUser = (user: User) => {
        this._user = user;
    };

    @computed
    get isLoggedIn() {
        return !!this.authToken;
    }

    @computed
    get isNotVerified() {
        return this._user?.status === UserStatus.NOT_VERIFIED
    }

    @computed
    get isActive() {
        return this._user?.status === UserStatus.ACTIVE;
    }

    @computed
    get canViewProfile() {
        return !!this._user;
    }

    @computed
    get canEditProfile() {
        return !!this._user && [UserStatus.DRAFT, UserStatus.NOT_VERIFIED].includes(this._user.status);
    }
}

export const authStore = new AuthStore();
